(function () {
  /**
   * @ngdoc controller
   * @name kmi.lms.admin.course.components.controller:registerUser
   *
   * @requires currentUser
   *
   * @description
   * Show register user action for training supervisor
   */

  angular.module('kmi.lms.course').directive('registerUser', registerUser);

  function registerUser() {
    return {
      restrict: 'EA',
      template: require('ajs/modules/course/components/register-user.html').default,
      controller: RegisterUserController,
      scope: {
        course: '=',
      },
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /*@ngInject*/
  function RegisterUserController(
    currentUser,
    $state,
    $uiRouterGlobals,
    $location,
    $window,
    locationHistory,
    globalConfig,
    elmsAuthService,
    moment,
  ) {
    var vm = this;

    vm.isImpersonated = elmsAuthService.isImpersonated();

    vm.impersonationRedirect = impersonationRedirect;
    vm.$onInit = onInit;

    function onInit() {
      vm.courseUrl = '/d/gateway/course/' + vm.course.id + '/view';
      vm.availableForRegistration =
        !vm.course.publishDate || moment(vm.course.publishDate).diff(moment(), 'hours') <= 0;

      vm.hasSupervisorPermission = currentUser.get().checkPermission('user.supervisor');
    }

    function impersonationRedirect() {
      // Save current state to history and hold it to the cookies.
      locationHistory.push({
        name: $state.current.name,
        params: angular.extend($uiRouterGlobals.params, $location.search()),
      });

      locationHistory.holdLastVisit();

      $window.location.href = globalConfig.base + 'admin/admin/users/impersonation?targetPath=' + vm.courseUrl;
    }
  }
})();
