(function () {
  angular.module('kmi.lms.competency').factory('competencyService', competencyService);

  function competencyService($http, $cacheFactory) {
    let service = {
      getModelsStructure: getModelsStructure,
      getModels: getModels,
    };

    let competenciesCache = $cacheFactory('competenciesCache');

    function getModelsStructure(query, ignoreCache, timoutDefer) {
      let requestParams = {
        params: undefined,
        cache: undefined,
        timeout: undefined,
      };

      if (query) {
        requestParams.params = { query: angular.toJson(query) };
      }

      if (!ignoreCache) {
        requestParams.cache = competenciesCache;
      }

      if (timoutDefer) {
        requestParams.timeout = timoutDefer.promise;
      }

      return $http.get('/a/competency/train/models_structure/', requestParams);
    }

    function getModels(query, ignoreCache) {
      let requestParams = { params: undefined, cache: undefined };

      if (query) {
        requestParams.params = { query: angular.toJson(query) };
      }

      if (!ignoreCache) {
        requestParams.cache = competenciesCache;
      }

      return $http.get('/a/competency/train/models/', requestParams);
    }

    return service;
  }
})();
